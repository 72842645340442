<template>
  <div :class="classes" :style="{top: y, left: x}">
    {{piece.owner}}
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

type Piece = {
  x: number;
  y: number;
  owner: string;
}
export default defineComponent({
  name: 'table-component',
  props: {
    piece: Object as PropType<Piece>,
    name: String as PropType<string>,
  },
  computed: {
    classes(): string {
      if (this.piece && this.name && this.piece.owner === this.name) {
        return 'piece my-piece outline-text';
      }
      return 'piece outline-text';
    },
    x(): string {
      const value = this.piece ? this.piece.x : 0;
      return `${value}px`;
    },
    y(): string {
      const value = this.piece ? this.piece.y : 0;
      return `${value}px`;
    },
  },
});
</script>
