import { Point } from './types';

const fi: Point[] = [
  {
    id: 1,
    shortTitle: '1. Metro',
    title: '1. Metro',
    description: ['Matkasi alkaa. Onnea seikkailuun!'],
  },
  {
    id: 2,
    shortTitle: '2. A Bloc',
    title: '2. A Bloc',
    description: ['Matkalle tarvitaan juomista. heitä noppaa ja juo silmäluvun verran.'],
  },
  {
    id: 3,
    shortTitle: '3. pHuone',
    title: '3. pHuone',
    description: ['Heitä noppaa. Jos silmäluvuilla 1-3 menet käymään VK:n kiltiksellä ja kaikki koeporatut juovat 3. Silmäluvuilla 4-6 juot itse 3.'],
  },
  {
    id: 4,
    shortTitle: '4. Keto',
    title: '4. Keskustoimisto',
    description: ['Otat biilin alle. Liiku seuraavalla vuorolla isomman silmäluvun mukaan.'],
  },
  {
    id: 5,
    shortTitle: '5. X-burger',
    title: '5. X-burger',
    description: ['Tilaat tupla-x-burgerin. Seuraavalla vuorolla juomamääräsi tuplaantuvat.'],
  },
  {
    id: 6,
    shortTitle: '6. Radisson Blu',
    title: '6. Radisson Blu',
    description: ['Nauti ystäviesi kanssa aamupala, kaikki pelaajat juovat 3.'],
  },
  {
    id: 7,
    shortTitle: '7. Unisport',
    title: '7. Unisport',
    description: ['Ota kovan treenin päätteeksi palautusjuomaa.'],
  },
  {
    id: 8,
    shortTitle: '8. Dipoli',
    title: '8. Dipoli',
    description: ['Heitä yhtä noppaa. Silmäluvuilla 1-3 pääset ulos Dipolista ja silmäluvuilla 4-6 jäät Dipoliin seuraavaksi vuoroksi ja juot nopan silmälukun verran.'],
  },
  {
    id: 9,
    shortTitle: '9. Urdsgjallar',
    title: '9. Urdsgjallar (TF)',
    description: ['Storätartävlingen! Haasta toinen pelaaja kellotukseen. Jo aiemmin haastettua ei voi haastaa uudestaan. Voittaja saa heittää uudestaan välittömästi.'],

  }, {
    id: 10,
    shortTitle: '10. Urheilukenttä',
    title: '10. Urheilukenttä',
    description: ['Urheilukentällä muistelet 110 metrin aitoja. Juo juomasi loppuun.'],

  },
  {
    id: 11,
    shortTitle: '11. Otaranta 8',
    title: '11. Otaranta 8',
    description: ['Kaverilla on tuparit! Anna jollekin kanssapelaajallesi tuparilahjana piccolopullo kuohuvaa tai 6 huikkaa.'],

  },
  {
    id: 12,
    shortTitle: '12. Otakaari 20',
    title: '12. Otakaari 20',
    description: ['Geneerinen dokaussauna isolla puolella. Ota shotti (tai 5 huikkaa).'],

  },
  {
    id: 13,
    shortTitle: '13. Ossinsauna',
    title: '13. Ossinsauna',
    description: ['Tilasta on ehdottomasti poistuttava kello 23, koska saunatila sijaitsee asuintalossa. Siirry Jämeräntaival 1:n ja suorita.'],

  },
  {
    id: 14,
    shortTitle: '14. JMT 1',
    title: '14. Jämeräntaival 1',
    description: [
      'Olet Jämeräntaipaleella. Jos heität kaksi samaa silmälukua yhdellä heitolla, siirryt ensimmäiset kaksi kertaa Jämeräntaival 1:n ja suoritat.',
      'Heitä noppaa. Silmäluvuilla 4-6 kanssapelaajat juovat 3 huikkaa ja silmäluvuilla 1-3 juot itse.'],
  },
  {
    id: 15,
    shortTitle: '15. Polyteekkarimuseo',
    title: '15. Polyteekkarimuseo',
    description: [
      'Olet Jämeräntaipaleella. Jos heität kaksi samaa silmälukua yhdellä heitolla, siirryt ensimmäiset kaksi kertaa Jämeräntaival 1:n ja suoritat.',
      'Jokainen sinua nuorempi tieteenharjoittaja pelissä juo 2. Jos olet nuorin, juo 3.',
    ],

  },
  {
    id: 16,
    shortTitle: '16. Smökki',
    title: '16. Smökki',
    description: [
      'Olet Jämeräntaipaleella. Jos heität kaksi samaa silmälukua yhdellä heitolla, siirryt ensimmäiset kaksi kertaa Jämeräntaival 1:n ja suoritat.',
      'Sitsipäivä! Vedä yksi säkeistö jostain sitsilaulusta. Kanssapelaajat ja yleisö voi halutessaan liittyä seuraan. Lopuksi skoolatkaa pelaajien kesken ja juokaa. Jos et laula, juo juomasi loppuun.',
    ],
  },
  {
    id: 17,
    shortTitle: '17. Gorsu',
    title: '17. Gorsu',
    description: [
      'Olet Jämeräntaipaleella. Jos heität kaksi samaa silmälukua yhdellä heitolla, siirryt ensimmäiset kaksi kertaa Jämeräntaival 1:n ja suoritat.',
      'Pelaatte Otaniemipeliä, jaa 15. Huom! Otaniemipeliä pelataan vain kerran illassa, ja pelin jälkeen Gorsu muuttuu geneeriseksi dokaussaunaksi, jolloin kaikki pelaajat juovat kaksi.',
    ],
  },
  {
    id: 18,
    shortTitle: '18. Rantasauna',
    title: '18. Rantasauna',
    description: [
      'Olet Jämeräntaipaleella. Jos heität kaksi samaa silmälukua yhdellä heitolla, siirryt ensimmäiset kaksi kertaa Jämeräntaival 1:n ja suoritat.',
      'Tuomari tarjoilee paljuvettä. Nauti!',
    ],
  },
  {
    id: 19,
    shortTitle: '19. JMT 6',
    title: '19. Jämeräntaival 6',
    description: [
      'Olet Jämeräntaipaleella. Jos heität kaksi samaa silmälukua yhdellä heitolla, siirryt ensimmäiset kaksi kertaa Jämeräntaival 1:n ja suoritat.',
      'Missä on jatkot? Heitä noppaa. Jos heität silmäluvun 5 tai 6, tiedät mihin kerrokseen suuntaat ja juot kaksi huikkaa. Silmäluvulla 1 päädyt silmäilemään kirjahyllyä - tuomari tarjoilee. Silmäluvuilla 2-4 ei voittoa tällä kertaa, siirry Jämeräntaival 1:n.',
      'Voit päätyä etsimään jatkoja Jämeräntaival 6:sta vain kerran pelin aikana. Jos päädyt uudestaan Jämeräntaival 6:n, ohita tehtävänanto.',
    ],
  },
  {
    id: 20,
    shortTitle: '20. Kappeli',
    title: '20. Kappeli',
    description: [
      'Olet Jämeräntaipaleella. Jos heität kaksi samaa silmälukua yhdellä heitolla, siirryt ensimmäiset kaksi kertaa Jämeräntaival 1:n ja suoritat.',
      'Hiljennyt hartauteen. Vietä kierros hiljaa, et saa sanoa mitään. Sakkona 5 hörppyä.'],
  },
  {
    id: 21,
    shortTitle: '21. (P)erhekylä',
    title: '21. (P)erhekylä',
    description: ['(E)ukkosi kieltää sua juomasta, kaikki sinkut juo kolme.'],

  },
  {
    id: 22,
    shortTitle: '22. Niemenkärki',
    title: '22. Niemenkärki',
    description: ['Ihaile maisemia ja juo kaksi.'],
  },
  {
    id: 23,
    shortTitle: '23. YTHS',
    title: '23. YTHS',
    description: ['Luulit olevasi kipeä, tosin lääkärin mielestä vain krapulassa. Juo vettä.'],

  },
  {
    id: 24,
    shortTitle: '24. A Grid',
    title: '24. A Grid',
    description: ['Kaikki SIK:in ja Inkubion jäsenet juovat 3 vanhojen kiltahuoneiden muistolle.'],

  },
  {
    id: 25,
    shortTitle: '25. Ossinlampi',
    title: '25. Ossinlampi',
    description: ['Delirium iskee. Näet lammessa vesiputouksen. Voit suorittaa.'],
  },
  {
    id: 26,
    shortTitle: '26. Inkubion kiltis',
    title: '26. Inkubion kiltahuone',
    description: ['Haasta joku kanssapelaaja peukkupainiin. Häviäjä juo kolme.'],
  },
  {
    id: 27,
    shortTitle: '27. FKn kiltis',
    title: '27. Fyysikkokillan kiltahuone',
    description: ['Ajan rakenne muuttuu ja magneettinavat vaihtavat paikkaa. Heitä uusiksi ja liiku taaksepäin. Lörs.'],
  },
  {
    id: 28,
    shortTitle: '28. KIKin kiltis',
    title: '28. Koneinsinöörikillan kiltahuone',
    description: ['Siirry jonkun pelitoverin luokse Tela-Veran kyydissä. Pakko mennä.'],
  },
  {
    id: 29,
    shortTitle: '29. MK & IK kiltis',
    title: '29. Maanmittarikillan ja Rakennusinsinöörikillan kiltahuoneet',
    description: [
      'Heitä noppaa. Parillisilla silmäluvuilla MK:n kiltikselle, parittomilla IK:n kiltikselle.',
      'MK:n kiltis: Perjantaipullo! Valitse numero ja heitä noppaa. Jos osuu, valitsemasi pelaaja juo shotin (tai viisi huikkaa). Jos ei, sinä juot.',
      'IK:n kiltis: Raksa jaksaa, uusi heittovuoro.',
    ],
  },
  {
    id: 30,
    shortTitle: '30. Maarintalo',
    title: '30. Maarintalo',
    description: ['Vietät allnighterin ja menetät ensi vuoron.'],
  },
  {
    id: 31,
    shortTitle: '31. Espilä',
    title: '31. Espilä',
    description: ['Networkkaa business-maailmassa - siirry Prodekon kiltikselle.'],
  },
  {
    id: 32,
    shortTitle: '32. TiK kiltis',
    title: '32. Tietokillan kiltahuone',
    description: ['TiKki jakaa lakit, eli kaikki TiKkiläiset jakaa kaksi.'],
  }, {
    id: 33,
    shortTitle: '33. Athenen kiltis',
    title: '33. Athenen kiltahuone',
    description: ['Networkkaa informaatioverkostojen maailmassa, juo kahden valitsemasi joukkueen kanssa 6 huikkaa (per joukkue).'],
  },
  {
    id: 34,
    shortTitle: '34. Subway',
    title: '34. Subway',
    description: ['Kassa on taas vaihteeksi kassalla. Jää seuraavaksi vuoroksi odottamaan subisi valmistumista.'],
  },
  {
    id: 35,
    shortTitle: '35. AS:n kiltis',
    title: '35. AS:n kiltahuone',
    description: [
      'AS-huikka: Muut pelaajat toteuttavat sinulle drinkin, tarjoten yhden komponentin per pelaaja. Nauti.',
      'Koronaproof-versio: vedä shotti tai tyhjennä juomasi.'],
  },
  {
    id: 36,
    shortTitle: '36. SIK:n kiltis',
    title: '36. SIK:n kiltahuone',
    description: ['Øhlhæfv! Juo juomasi loppuun.'],
  },
  {
    id: 37,
    shortTitle: '37. Prodekon kiltis',
    title: '37. Prodekon kiltahuone',
    description: ['Networkkaa business-maailmassa, siirry Espilään.'],
  },
  {
    id: 38,
    shortTitle: '38. Kauppakorkeakoulu',
    title: '38. Kauppakorkeakoulu',
    description: ['Nopeat kurssidedikset hiljaisessa työskentelytilassa. Vietä kierros hiljaa, et saa sanoa mitään. Sakkona 5 hörppyä.'],
  },
  {
    id: 39,
    shortTitle: '39. Väre',
    title: '39. Väre',
    description: ['Yrität ymmärtää taidetta. Kalja ymmärtää sinua paremmin. Juo kolme.'],
  },
  {
    id: 40,
    shortTitle: '40. ARTS-terassi',
    title: '40. ARTS-terassi ja ainejärjestötilat',
    description: ['Terassilla on karaoke. Ole seuraava vuoro siinä ja juo vuosikurssisi verran huikkia.'],
  },
  {
    id: 41,
    shortTitle: '41. Amfi: maali',
    title: '41. Amfi: maali',
    description: ['Taas mennään. Juo pelijuomasi loppuun ja olet valmis jatkoille!'],
  },
];

const en: Point[] = [
  {
    id: 1,
    shortTitle: '1. Metro',
    title: '1. Metro',
    description: ['Your journey begins. Good luck on your adventure!'],
  },
  {
    id: 2,
    shortTitle: '2. A Bloc',
    title: '2. A Bloc',
    description: [
      'A long trip is thirsty work! Roll a die and drink that many sips.',
    ],
  },
  {
    id: 3,
    shortTitle: '3. pHuone',
    title: '3. pHuone',
    description: [
      'Roll a die. ',
      '1-3: you visit the VK guild room and all trial drilled people drink 3.',
      '4-6: you drink 3.',
    ],
  },
  {
    id: 4,
    shortTitle: '4. Central office',
    title: '4. Central office',
    description: [
      'You hitch a ride. You move based on your highest roll on your next turn.',
    ],
  },
  {
    id: 5,
    shortTitle: '5. X-burger',
    title: '5. X-burger',
    description: [
      'You order a double-x-burger. Your drinks are doubled on your next turn.',
    ],
  },
  {
    id: 6,
    shortTitle: '6. Radisson Blu',
    title: '6. Radisson Blu',
    description: ['You enjoy breakfast with your friends. Everyone drinks 3.'],
  },
  {
    id: 7,
    shortTitle: '7. Unisport',
    title: '7. Unisport',
    description: ['A hard workout has made you thristy. Drink 1.'],
  },
  {
    id: 8,
    shortTitle: '8. Dipoli',
    title: '8. Dipoli',
    description: [
      'Roll a die. ',
      '1-3: you manage to escape Dipoli. ',
      "4-6: you're stuck in Dipoli and lose your next turn, and must take sips equal to your roll.",
    ],
  },
  {
    id: 9,
    shortTitle: '9. TF',
    title: '9. Urdsgjallar (TF)',
    description: [
      'Storätartävlingen! You challenge another player to a drinking contest. You may not choose someone who has been challenged before. The first contestant to finish a full drink immediately gets a bonus turn.',
    ],
  },
  {
    id: 10,
    shortTitle: '10. Sports field',
    title: '10. Sports field',
    description: ['You reminisce about 110 meter hurdles. Finish your drink.'],
  },
  {
    id: 11,
    shortTitle: '11. Otaranta 8',
    title: '11. Otaranta 8',
    description: [
      "It's your friend's housewarming party! Give any player a piccolo bottle of sparkling or 6 sips as a housewarming gift.",
    ],
  },
  {
    id: 12,
    shortTitle: '12. Otakaari 20',
    title: '12. Otakaari 20',
    description: [
      'Generic drunken sauna evening on the big side. Take a shot (or 5 sips).',
    ],
  },
  {
    id: 13,
    shortTitle: '13. Ossinsauna',
    title: '13. Ossinsauna',
    description: [
      'This space absolutely must be vacated by 23:00, as the sauna is in an apartment building. Move to Jämeräntaival 1 and complete the task in that space.',
    ],
  },
  {
    id: 14,
    shortTitle: '14. JMT 1',
    title: '14. Jämeräntaival 1',
    description: [
      'You are on Jämeräntaival. The first two times both of your dice show the same result on a single throw, move to Jämeräntaival 1 and complete the task in that space.',
      'Roll a die. ',
      '1-3: you drink 3.', '4-6: all other players drink 3.',
    ],
  },
  {
    id: 15,
    shortTitle: '15. Museum',
    title: '15. Polytechnic museum',
    description: [
      'You are on Jämeräntaival. The first two times both of your dice show the same result on a single throw, move to Jämeräntaival 1 and complete the task in that space.',
      'Each player younger than you drinks 2. If you are the youngest, drink 3.',
    ],
  },
  {
    id: 16,
    shortTitle: '16. Smökki',
    title: '16. Smökki',
    description: [
      'You are on Jämeräntaival. The first two times both of your dice show the same result on a single throw, move to Jämeräntaival 1 and complete the task in that space.',
      'Sitsit day! Sing one verse of any sitsit song. Other players and the audience may join in. After singing, toast with the players around you and drink. If you do not sing, finish your drink.',
    ],
  },
  {
    id: 17,
    shortTitle: '17. Gorsu',
    title: '17. Gorsu',
    description: [
      'You are on Jämeräntaival. The first two times both of your dice show the same result on a single throw, move to Jämeräntaival 1 and complete the task in that space.',
      "You're playing the Otaniemi Game; deal out 15 sips. ",
      'Note! The Otaniemi Game can only be played once every evening; after this, Gorsu becomes a generic drinking sauna where all players must drink 2.',
    ],
  },
  {
    id: 18,
    shortTitle: '18. Rantasauna',
    title: '18. Rantasauna',
    description: [
      'You are on Jämeräntaival. The first two times both of your dice show the same result on a single throw, move to Jämeräntaival 1 and complete the task in that space.',
      'The referee is serving fresh hot tub water. Enjoy!',
    ],
  },
  {
    id: 19,
    shortTitle: '19. JMT 6',
    title: '19. Jämeräntaival 6',
    description: [
      'You are on Jämeräntaival. The first two times both of your dice show the same result on a single throw, move to Jämeräntaival 1 and complete the task in that space.',
      "Where's the after-party? Roll a die. If you roll a 5 or 6, you know which floor you're heading to and take 2 sips. If you roll a 1, you start eyeballing the bookshelf - the referee will serve you a drink. If you roll a 2-4, no luck this time: move to Jämeräntaival 1.",
      'You can only search for the after-party in Jämeräntaival 6 once during the game. If you end up in this space again, ignore the task.',
    ],
  },
  {
    id: 20,
    shortTitle: '20. Otaniemi Chapel',
    title: '20. Otaniemi Chapel',
    description: [
      'You are on Jämeräntaival. The first two times both of your dice show the same result on a single throw, move to Jämeräntaival 1 and complete the task in that space.',
      'You sit in silent devotion until your next turn. Speaking incurs a 5 sip penalty.',
    ],
  },
  {
    id: 21,
    shortTitle: '21. (P)erhekylä',
    title: '21. (P)erhekylä',
    description: ['Your partner tells you not to drink. Single players drink 3.'],
  },
  {
    id: 22,
    shortTitle: '22. Niemenkärki',
    title: '22. Niemenkärki - tip of the peninsula',
    description: ['Marvel at the sights and drink 2.'],
  },
  {
    id: 23,
    shortTitle: '23. YTHS',
    title: '23. YTHS',
    description: [
      "You thought you might be sick, though the doctor thinks you're just hung over. Drink water."],
  },
  {
    id: 24,
    shortTitle: '24. A Grid',
    title: '24. A Grid',
    description: ['All members of SIK:in and Inkubion drink 3 to the memory of their old guild rooms.'],
  },
  {
    id: 25,
    shortTitle: '25. Ossinlampi',
    title: '25. Ossinlampi',
    description: [
      'Delirium. In the pond you see a waterfall. You may start when ready.',
    ],
  },
  {
    id: 26,
    shortTitle: '26. Inkubio guild room',
    title: '26. Inkubio guild room',
    description: ['Challenge another player to a thumb war. The loser drinks 3.'],
  },
  {
    id: 27,
    shortTitle: '27. FK guild room',
    title: '27. Fyysikkokilta guild room',
    description: [
      'The construct of time changes and the magnetic poles are reversed. Roll again and move backwards. Lörs!',
    ],
  },
  {
    id: 28,
    shortTitle: '28. KIK guild room',
    title: '28. Koneinsinöörikilta guild room',
    description: ["Ride Tela-Vera to another player's space. Gotta go!"],
  },
  {
    id: 29,
    shortTitle: '29. MK & IK guild rooms',
    title: '29. Maanmittarikilta and Rakennusinsinöörikilta guild rooms',
    description: [
      'Roll a die. On an even result, go to the MK guild room; on an odd result, go to the IK guild room.',
      'MK guild room: Friday bottle! Pick a number and roll a die. If you chose correctly, choose a player to drink a shot (or 5 sips). If not, you drink.',
      'IK guild room: Raksa jaksaa, get another turn.',
    ],
  },
  {
    id: 30,
    shortTitle: '30. Maarintalo',
    title: '30. Maarintalo',
    description: ['You do an all-nighter and lose your next turn.'],
  },
  {
    id: 31,
    shortTitle: '31. Espilä',
    title: '31. Espilä',
    description: [
      'You do some networking in the business world - move to the Prodeko guild room.',
    ],
  },
  {
    id: 32,
    shortTitle: '32. TiK guild room',
    title: '32. Tietokilta guild room',
    description: ['TiK is dealing out the caps, so all TiK members give 2 sips.'],
  },
  {
    id: 33,
    shortTitle: '33. Athene guild room',
    title: '33. Athene guild room',
    description: [
      'You do some networking in the information networking world - select 2 teams with which to drink 6 sips per team.',
    ],
  },
  {
    id: 34,
    shortTitle: '34. Subwayc',
    title: '34. Subway',
    description: [
      'The waitress is once again stuck at the register. Spend next turn waiting on the waitress.',
    ],
  },
  {
    id: 35,
    shortTitle: '35. AS guild room',
    title: '35. AS guild room',
    description: [
      'AS-sip: Other players make you a drink, providing one component per player. Enjoy!',
      'Corona-proof version: take a shot or finish your drink.',
    ],
  },
  {
    id: 36,
    shortTitle: '36. SIK guild room',
    title: '36. SIK guild room',
    description: ['Øhlhæfv! Finish your drink.'],
  },
  {
    id: 37,
    shortTitle: '37. Prodeko guild room',
    title: '37. Prodeko guild room',
    description: [
      'You do some networking in the business world - move to Epsilä.',
    ],
  },
  {
    id: 38,
    shortTitle: '38. School of Economics',
    title: '38. School of Economics',
    description: [
      'You quickly finish up some coursework in a silent study area. Spend your turn in silence, without speaking a word. 5 sip penalty. ',
    ],
  },
  {
    id: 39,
    shortTitle: '39. Väre',
    title: '39. Väre',
    description: [
      'You attempt to understand art. Beer understands you better. Drink 3.',
    ],
  },
  {
    id: 40,
    shortTitle: '40. ARTS terrace',
    title: '40. ARTS terrace and subject organization space',
    description: [
      "There's a karaoke machine on the terrace. Stay there for your next turn and drink as many sips as the years you've been studying.",
    ],
  },
  {
    id: 41,
    shortTitle: '41. Amfi: finish',
    title: '41. Amfi: finish',
    description: [
      "Here we go again. Finish your outstanding game drinks and you're ready for the after-party!",
    ],
  },
];

export const points: {[key: string]: Point[]} = {
  fi,
  en,
};

const amountOfPoints = points.fi.length;

export const locations: [number, number][] = [
  [669, 311],
  [646, 279],
  [807, 281],
  [675, 482],
  [744, 520],
  [730, 704],
  [688, 673],
  [647, 559],
  [571, 573],
  [600, 641],
  [552, 725],
  [513, 611],
  [419, 656],
  [496, 650],
  [473, 708],
  [410, 707],
  [433, 770],
  [413, 811],
  [408, 741],
  [330, 739],
  [208, 735],
  [44, 764],
  [214, 537],
  [363, 477],
  [403, 530],
  [499, 410],
  [522, 402],
  [479, 360],
  [483, 332],
  [343, 332],
  [473, 188],
  [505, 193],
  [522, 174],
  [487, 145],
  [469, 52],
  [499, 12],
  [487, 36],
  [572, 196],
  [602, 271],
  [644, 351],
  [602, 382],
];

export default {
  points,
  amountOfPoints,
  locations,
};
