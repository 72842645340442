
import { GameState, Player, Point } from '@/utils/types';
import { defineComponent, PropType } from 'vue';
import Piece from '@/components/Piece.vue';
import { locations, points } from '../utils/points';

type PieceData = {
  x: number;
  y: number;
  owner: string;
}

type ComponentData = {
  ratio: number;
  pieces: PieceData[];
}

export default defineComponent({
  name: 'game-board',
  props: {
    game: Object as PropType<GameState>,
    debug: Boolean as PropType<boolean>,
  },
  data(): ComponentData {
    return {
      ratio: 1,
      pieces: [],
    };
  },
  mounted(): void {
    window.addEventListener('resize', this.updateRatio);
    setTimeout(() => this.updateRatio(), 50);
    this.updatePositions();
  },
  unmounted(): void {
    window.removeEventListener('resize', this.updateRatio);
  },
  watch: {
    game(): void {
      this.updatePositions();
    },
  },
  computed: {
    currentPoint(): Point | null {
      if (!this.currentPlayer) return null;
      return points[this.$i18n.locale][this.currentPlayer.progress];
    },
    currentPlayer(): Player | null {
      return this.game ? this.game.players[this.game.turn] : null;
    },
  },
  methods: {
    setPosition(piece: PieceData, progress: number): PieceData {
      const location = locations[progress];
      const [y, x] = location;
      /* eslint-disable-next-line */
      piece.x = x * this.ratio; piece.y = y * this.ratio;
      return { ...piece, x, y };
    },
    updateRatio(): void {
      const board = this.$refs.board as Element | null;
      if (!board) return;
      this.ratio = board.clientHeight / 900;
      this.updatePositions();
    },
    updatePositions(): void {
      if (!this.game) return;
      this.game.players.forEach((player) => {
        let piece = this.pieces.find((p) => p.owner === player.name);
        if (!piece) {
          piece = { x: 0, y: 0, owner: player.name };
          this.pieces.push(piece);
        }
        this.setPosition(piece, player.progress);
      });
    },
  },
  components: {
    Piece,
  },
});
